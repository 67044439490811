/* eslint-disable complexity */
import { Card, Space } from 'antd';
import dayjs, { extend } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { groupBy, map } from 'lodash-es';
import { includeCheckIn, includeLease, includeMonthly } from 'server/type-file';
import { endOfMonth, startOfMonth } from '../../../utils/timeUtil';
import { useApartmentList } from '../overview/useApartmentList';
import { calcTotal } from '../stat/calcTotal';
import { useBuildingConfig } from '../useBuildingConfig';
import { BlurComponent } from '../myInfo/BlurComponent';
extend(isBetween);

export default function UsedByMonth() {
	const { changeTitle, currentBuildingConfig } = useBuildingConfig();

	changeTitle('月度水电用量');

	const formTime = dayjs();

	const { list } = useApartmentList({
		startTime: startOfMonth(formTime.subtract(1, 'year')).valueOf(),
		endTime: endOfMonth(formTime).valueOf(),
		category: [includeMonthly, includeCheckIn, includeLease],
		chargeStatus: '0',
	});

	const transformData = groupBy(list, (i) =>
		dayjs(i.checkInTime).format('YYYY-MM'),
	);

	const stat = map(transformData, (grp, key) => {
		return {
			month: dayjs(grp?.[0]?.checkInTime).format('MM'),
			...calcTotal(grp),
			quantity: grp.length,
			grp: grp,
			year: dayjs(grp?.[0]?.checkInTime).format('YYYY'),
		};
	}).sort((r1, r2) => r2.checkInTime - r1.checkInTime);

	const config = {
		data: stat,
		// padding: 'auto',
		xField: 'month',
		yField: 'total',
		seriesField: 'year',
		xAxis: {
			// type: 'timeCat',
			// tickCount: 5,
		},
	};

	return (
		<Card>
			<div className="grid xs:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2">
				<div>
					<h3>电费</h3>
					<table>
						<thead>
							<tr>
								<td>记录月</td>
								<td>计费周期</td>
								<td>用电量/度</td>
								<td>电费/元</td>
							</tr>
						</thead>
						<tbody>
							{stat.map((i) => {
								return (
									<tr key={`${i.year}-${i.month}`}>
										<td>{`${i.year}.${i.month}`}</td>
										<td>{`${dayjs(`${i.year}-${i.month}`)
											.add(-1, 'month')
											.format('MM.DD')}~${endOfMonth(
											dayjs(`${i.year}-${i.month}`),
										)
											.add(-1, 'month')
											.format('MM.DD')}`}</td>
										<td>
											<BlurComponent>
												{i.totalElectricCharge.toString()}
											</BlurComponent>
										</td>
										<td>
											<BlurComponent>{i.electronUsed.toString()}</BlurComponent>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
				<div>
					<h3>水费</h3>

					<table>
						<thead>
							<tr>
								<td>记录月</td>
								<td>计费周期</td>
								<td>用水量/度</td>
								<td>水费/元</td>
							</tr>
						</thead>
						<tbody>
							{stat.map((i) => {
								return (
									<tr key={`${i.year}-${i.month}`}>
										<td>{`${i.year}.${i.month}`}</td>
										<td>{`${dayjs(`${i.year}-${i.month}`)
											.add(-1, 'month')
											.format('MM.DD')}~${endOfMonth(
											dayjs(`${i.year}-${i.month}`),
										)
											.add(-1, 'month')
											.format('MM.DD')}`}</td>
										<td>
											<BlurComponent>{i.totalWater.toString()}</BlurComponent>
										</td>
										<td>
											<BlurComponent>
												{i.totalColdWaterCharge.toString()}
											</BlurComponent>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>

				<div>
					<h3>热水费</h3>

					<table>
						<thead>
							<tr>
								<td>记录月</td>
								<td>计费周期</td>
								<td>用水量/度</td>
								<td>水费/元</td>
							</tr>
						</thead>
						<tbody>
							{stat.map((i) => {
								return (
									<tr key={`${i.year}-${i.month}`}>
										<td>{`${i.year}.${i.month}`}</td>
										<td>{`${dayjs(`${i.year}-${i.month}`)
											.add(-1, 'month')
											.format('MM.DD')}~${endOfMonth(
											dayjs(`${i.year}-${i.month}`),
										)
											.add(-1, 'month')
											.format('MM.DD')}`}</td>
										<td>
											<BlurComponent>{i.hotUsed.toString()}</BlurComponent>
										</td>
										<td>
											<BlurComponent>
												{i.totalHotWaterCharge.toString()}
											</BlurComponent>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
		</Card>
	);
}
